import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { ConnectedHome } from "./components/Home";
import { NotFound } from "./components/NotFound";
import { OAuth2Redirect } from "./components/OAuth2Redirect";
import { ConnectedViewPlan } from "./components/ViewPlan";


export const viewPlanPath = "/view/:guid";

export const routes = <Switch>
        <Route exact path="/" component={ ConnectedHome } />
        <Route exact path="/loginredirect" component={ OAuth2Redirect } />
        <Route path={viewPlanPath} component={ ConnectedViewPlan } />
        <Route component={ NotFound } />
    </Switch>;
