import { OAuth2Provider } from "./utils/oauth";


export type DeploymentType = "Development" | "Staging" | "Production";


export interface ServerOptions {
    currencyCode: string;
    freeMode: boolean;
    deploymentType: DeploymentType;
    oAuthConfigs: {
        [P in OAuth2Provider]?: {
            authorizeUri: string;
            clientId: string;
            provider: OAuth2Provider;
            redirectUri: string;
        };
    };
    publishedDataHost: string;
    stripePublicApiKey: string;
    uri: string;
}


const defaultOptions: ServerOptions = {
    currencyCode: "cad",
    deploymentType: "Staging",
    freeMode: false,
    oAuthConfigs: {
        [OAuth2Provider.TrimbleConnect]: {
            authorizeUri: "https://identity-stg.trimble.com/i/oauth2/authorize",
            clientId: "ZthTWnWfrkRR6kfn6rT9hhk0_qMa",
            provider: OAuth2Provider.TrimbleConnect,
            redirectUri: "http://localhost:3000/loginredirect"
        }
    },
    publishedDataHost: "static.construction.ai",
    stripePublicApiKey: "pk_test_TYooMQauvdEDq54NiTphI7jx",
    uri: "https://app.construction.ai/"
};


let serverOptions = defaultOptions;


export function setServerOptions(newServerOptions: ServerOptions): void {
    serverOptions = newServerOptions;
}


export function getServerOptions(): ServerOptions {
    return serverOptions;
}
