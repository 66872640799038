import * as React from "react";


export interface ButtonProps {
    id?: string;
    onClick: () => void;
    children: string | string[];
}


export function Button(props: ButtonProps): JSX.Element {
    return <button
        id={props.id}
        onClick={props.onClick}
        className="construction-ai-button"><span>{props.children}</span></button>;
}
