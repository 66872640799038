import * as React from "react";


export interface PicklistItem {
    id: string;
    name: string;
}


export interface PicklistProps<TItem extends PicklistItem> {
    id?: string;
    items: TItem[];
    onSelection: (selectedItem?: TItem) => void;
    selectedItem?: TItem;
}



export function Picklist<TItem extends PicklistItem>(props: PicklistProps<TItem>): JSX.Element {
    const picklistHandler = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onSelection(props.items.find(i => i.id === event.target.value));
    }, [props.onSelection]);

    return <select id={props.id} value={props.selectedItem && props.selectedItem.id} onChange={picklistHandler} className="construction-ai-picklist">
        {props.items.map(p => <option value={p.id} className="construction-ai-picklist-option">{p.name}</option>)}
    </select>;
}
