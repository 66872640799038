import * as React from "react";


export interface GoogleLogoProps {
    size: number;
    margin?: string;
}


export function GoogleLogo(props: GoogleLogoProps): JSX.Element {
    return <svg
        width={props.size}
        height={props.size}
        style={{
            margin: props.margin
        }}
        viewBox="0 0 4.7624998 4.7624998"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="layer1" transform="translate(-51.201225,-74.165142)">
            <path
                d="m 55.868475,76.600511 c 0,-0.168852 -0.01515,-0.33121 -0.04329,-0.487074 H 53.58248 v 0.921111 h 1.281545 c -0.0552,0.297656 -0.222971,0.549853 -0.475167,0.718705 v 0.597477 h 0.769577 c 0.450272,-0.414554 0.710045,-1.02502 0.710045,-1.750219 z"
                id="Shape"
                fill="#4285f4"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="0.264583" />
            <path
                d="m 53.582475,78.927642 c 0.642937,0 1.181966,-0.21323 1.575955,-0.576912 l -0.769577,-0.597477 c -0.21323,0.142875 -0.485992,0.227301 -0.806378,0.227301 -0.620207,0 -1.145165,-0.418884 -1.332418,-0.981725 h -0.795554 v 0.616961 c 0.391824,0.778236 1.19712,1.311852 2.127972,1.311852 z"
                id="path43"
                fill="#34a853"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="0.264583" />
            <path
                d="m 52.250057,76.998829 c -0.04762,-0.142875 -0.07468,-0.295491 -0.07468,-0.452437 0,-0.156946 0.02706,-0.309563 0.07468,-0.452438 v -0.61696 h -0.795554 c -0.161275,0.321469 -0.253278,0.685151 -0.253278,1.069398 0,0.384247 0.092,0.747929 0.253278,1.069398 z"
                id="path45"
                fill="#fbbc05"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="0.264583" />
            <path
                d="m 53.582475,75.11223 c 0.349611,0 0.663503,0.120145 0.910287,0.356105 l 0.682986,-0.682986 c -0.412389,-0.384247 -0.951418,-0.620207 -1.593273,-0.620207 -0.930852,0 -1.736148,0.533616 -2.127972,1.311852 l 0.795554,0.61696 c 0.187253,-0.56284 0.712211,-0.981724 1.332418,-0.981724 z"
                id="path47"
                fill="#ea4335"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="0.264583" />
        </g>
    </svg>;
}
