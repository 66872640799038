import { parse, stringify } from "qs";
import * as Rx from "rxjs";
import { map } from "rxjs/operators";


/**
 * The set of all possible OAuth2 providers.
 */
export enum OAuth2Provider {
    TrimbleConnect = "TrimbleConnect",
    Google = "Google",
    Procore = "Procore"
}


export interface OAuth2LoginResultBase {
    success: boolean;
}

export interface OAuth2LoginResultSuccess {
    success: true;
    authorizationCode: string;
}

export interface OAuth2LoginResultFailure {
    success: false;
    error: string;
    errorMessage?: string;
}

export type OAuth2LoginResult = OAuth2LoginResultFailure | OAuth2LoginResultSuccess;

export type OAuth2LoginResultWithProvider = OAuth2LoginResult & {
    provider: OAuth2Provider;
};


function isOAuth2LoginResult(obj: any): obj is OAuth2LoginResult {
    return obj && typeof obj.success === "boolean"
        && (typeof obj.authorizationCode === "string" || typeof obj.error === "string");
}


/** Construct and return an OAuth2 authorization URI. */
export function createAuthorizationUri(authorizeUri: string, clientId: string, redirectUri: string, provider: OAuth2Provider): string {
    const state = "bacon";
    const scope = provider === OAuth2Provider.TrimbleConnect ? [ "openid", "Construction_AI" ]
        : provider === OAuth2Provider.Google ? ["email", "profile"]
        : undefined;
    const tenantDomain = provider === OAuth2Provider.TrimbleConnect
        ? "trimble.com"
        : undefined;
    const prompt = provider === OAuth2Provider.Google
        ? "consent"
        : undefined;
    const queryParams = {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: "code",
        state: state,
        scope: scope ? scope.join(" ") : undefined,
        access_type: "offline",
        prompt,
        tenantDomain
    };

    const serializedParams = stringify(queryParams);

    return `${authorizeUri}${authorizeUri.indexOf("?") < 0 ? "?" : "&"}${serializedParams}`;
}


interface LoginWithAUth2Options {
    authorizeUri: string;
    clientId: string;
    provider: OAuth2Provider;
    redirectUri: string;
}


/**
 * Attempt to log in using OAuth2 by opening a popup window with the authorization URL.
 *
 * The redirect URL should forward the result to the main window by calling
 * "sendResultMessage".
 */
export function loginWithOAuth2(options: LoginWithAUth2Options): Rx.Observable<OAuth2LoginResultWithProvider> {
    return new Rx.Observable<OAuth2LoginResult>(subscriber => {
        let cleanup = () => { return; };

        function receivePopupMessage(event: MessageEvent): void {
            if (event.origin === window.location.origin) {
                cleanup();
                if (isOAuth2LoginResult(event.data)) {
                    subscriber.next(event.data);
                } else {
                    subscriber.next({
                        success: false,
                        error: "Got invalid message."
                    });
                }
                subscriber.complete();
            }
        }

        const authorizationUri = createAuthorizationUri(options.authorizeUri, options.clientId, options.redirectUri, options.provider);
        const popup = window.open(authorizationUri, "_blank", "width=600,height=600");
        window.addEventListener("message", receivePopupMessage, false);
        const pollTimer = window.setInterval(() => {
            if (!popup || popup.closed !== false) {
                cleanup();
                subscriber.next({
                    success: false,
                    error: "Closed popup",
                    errorMessage: "Login dialog was closed."
                });
                subscriber.complete();
            }
        }, 200);

        cleanup = () => {
            window.removeEventListener("message", receivePopupMessage, false);
            window.clearInterval(pollTimer);
            if (popup && !popup.closed) {
                popup.close();
            }
        };

        return () => {
            cleanup();
        };
    }).pipe(map(result => ({
        ...result,
        provider: options.provider
    })));
}


/**
 * Attempts to send the result of an OAuth2 login to the parent window.
 */
export function sendResultMessage(location: Location): void {
    // TODO possibility: oauthErrorCode, oauthErrorMsg

    const params = parse(location.search, { ignoreQueryPrefix: true });
    const result: OAuth2LoginResult = params.error
        ? {
            success: false,
            error: params.error,
            errorMessage: params.error_description
        }
        : params.code
            ? {
                success: true,
                authorizationCode: params.code
            }
            : {
                success: false,
                error: `Could not find code or error in URL ${location}`,
                params
            } as any;

    if (window.opener) {
        window.opener.postMessage(result, window.location.origin);
    }
}
