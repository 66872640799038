import * as React from "react";

import { ThreeDots } from "./ThreeDots";


export interface PleaseWaitProps {
    children: string | (string | undefined)[];
}


export function PleaseWait(props: PleaseWaitProps): JSX.Element {
    return <p className="always-visible">{props.children}&nbsp;&nbsp;&nbsp;<ThreeDots color="#597AFF" height={12} width={48}></ThreeDots></p>;
}
