import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import * as classnames from "classnames";
import * as React from "react";

import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { PleaseWait } from "./PleaseWait";
import { Textbox } from "./Textbox";
import { TrimbleProject, TrimbleProjectList } from "./TrimbleProjectList";


export type StripeSubmitCallback = (stripeToken: string, email: string, selectedProject?: TrimbleProject) => void;

export interface CheckoutFormProps {
    clearError: () => void;
    costDisplay: string;
    currencyCode: string;
    email: string | null;
    errorText?: string;
    isPaying: boolean;
    isTrimbleEmail: boolean;
    onSubmit: StripeSubmitCallback;
}


export function CheckoutForm(props: CheckoutFormProps): JSX.Element {
    const [useTrimble, setUseTrimble] = React.useState(false);
    const [typedEmail, setTypedEmail] = React.useState<string>();
    const [selectedProject, setSelectedProject] = React.useState<TrimbleProject>();
    const [waitingForStripe, setWaitingForStripe] = React.useState(false);
    const [internalErrorText, setInternalErrorText] = React.useState<string>();
    const clearErrorCallback = React.useCallback(() => {
        if (internalErrorText) {
            setInternalErrorText(undefined);
        } else {
            props.clearError();
        }
    }, [internalErrorText, props.clearError]);

    const stripe = useStripe();
    const stripeElements = useElements();

    const submitCallback = React.useCallback(
        createCallback(
            props.email || typedEmail,
            useTrimble,
            selectedProject,
            stripe,
            stripeElements,
            props.onSubmit,
            setWaitingForStripe,
            setInternalErrorText),
        [props.email, typedEmail, useTrimble, selectedProject, stripe, stripeElements, setWaitingForStripe, setInternalErrorText]);

    const classes = classnames([
        "checkout",
        props.isPaying || props.errorText || waitingForStripe ? "checkout--disabled" : undefined
    ]);

    const errorText = internalErrorText || props.errorText;
    const button = errorText || props.isPaying || waitingForStripe
        ? undefined
        : <Button onClick={submitCallback}>Pay {props.costDisplay} {props.currencyCode}</Button>;
    const status = errorText
        ? <p className="always-visible">Error encountered on payment: "{errorText}" <a className="link" onClick={clearErrorCallback}>Try again?</a></p>
        : props.isPaying || waitingForStripe
            ? <PleaseWait>Processing payment of {props.costDisplay} {props.currencyCode}</PleaseWait>
            : undefined;

    return <div>
        <div className={classes} >
            <p>To purchase, please provide the following information:</p>
            <CardElement></CardElement>
            {!props.email && <Textbox label="Email" id="email" value={typedEmail} onChange={setTypedEmail} type="email"></Textbox>}
            {props.email && props.isTrimbleEmail && <Checkbox checked={useTrimble} id="saveToTrimble" onChange={setUseTrimble} >Save to Trimble Connect</Checkbox>}
            {props.email && useTrimble && <TrimbleProjectList onSelection={setSelectedProject} selectedProject={selectedProject} />}
            {button}
        </div>
        {status}
    </div>;
}


type SetState<S> = React.Dispatch<React.SetStateAction<S>>;


function createCallback(
    email: string | undefined,
    useTrimble: boolean,
    selectedProject: TrimbleProject | undefined,
    stripe: Stripe | null,
    stripeElements: StripeElements | null,
    onSubmit: StripeSubmitCallback,
    setWaitingForStripe: SetState<boolean>,
    setInternalErrorText: SetState<string | undefined>): () => void {
    return () => {
        if (!stripe || !stripeElements) {
            // this shouldn't happen
            console.error("Got null stripe object.");
            return;
        }

        const cardElement = stripeElements.getElement(CardElement);

        if (!cardElement) {
            // this shouldn't happen
            console.error("Got null stripe CardElement object.");
            return;
        }

        if (!email) {
            setInternalErrorText("Email must be specified.");
            return;
        }

        if (useTrimble && !selectedProject) {
            setInternalErrorText("Please select a project.");
            return;
        }

        setWaitingForStripe(true);

        stripe
            .createToken(cardElement)
            .then(result => {
                if (result.token) {
                    onSubmit(result.token.id, email, selectedProject);
                    setWaitingForStripe(false);
                } else {
                    setWaitingForStripe(false);
                    if (result.error) {
                        setInternalErrorText(result.error.message);
                    } else {
                        setInternalErrorText("Failed to get token from Stripe");
                    }
                }
            })
            .catch(error => {
                console.error("Got unexpected error: ", error);
                setWaitingForStripe(false);
                setInternalErrorText(`Failed to get token from Stripe: ${error}`);
            });
    };
}
