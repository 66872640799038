import { createMatchSelector, LocationChangeAction, LOCATION_CHANGE, RouterRootState } from "connected-react-router";
import { Action, Reducer } from "redux";
import { combineEpics, StateObservable } from "redux-observable";
import * as Rx from "rxjs";
import { catchError, flatMap, map, switchMap } from "rxjs/operators";

import { viewPlanPath } from "../routes";
import { createFetchStream } from "../utils/fetch";
import { actionCreator, ofType, ActionsUnion, ActionTypes } from "../utils/state";

import { stringify } from "qs";
import { TrimbleProject } from "../components/TrimbleProjectList";
import { ApplicationState } from "./index";


// -----------------
// State

export interface PlansState {
    errorText?: string;
    isLoading: boolean;
    isPaying: boolean;
    currentPlanGuid?: string;
    plans: {
        [guid: string]: PlanData
    };
}

export interface PlanData {
    available: boolean;
    costDisplay: string;
    costInCents: number;
    crossSectionData: CrossSectionPlanData;
    featurePages: FeaturePageData[];
    free: boolean;
    guid: string;
    layeredPages: LayeredPageData[];
    nameWithoutExtension: string;
    nothing: boolean;
    paid: boolean;
    spotPages: SpotPageData[];
    tableData: TablePlanData;
}

export interface FeaturePageData {
    featureCount: number;
    featuresIdentified: FeatureInformation[];
    pageNumber: number;
    pdfOutputFile: string;
}

export interface LayeredPageData {
    dxfOutputFile: string;
    pageNumber: number;
    pdfOutputFile: string;
}

export interface SpotPageData {
    dxfOutputFile: string;
    layerCount: number;
    pageNumber: number;
    pdfOutputFile: string;
    spotsConfident: number;
    spotsFound: number;
    spotsUncertain: number;
    spotsUnmatched: number;
    timeSaved: string;
    timeSavedInSeconds: number;
    vectorCount: number;
}

export interface TablePlanData {
    excelOutputFile: string;
    pdfOutputFile: string;
    tableCount: number;
}

export interface CrossSectionPlanData {
    dxfOutputFile: string;
    crossSectionCount: number;
}

export interface FeatureInformation {
    name: string;
    count: number;
}

export const initialState: PlansState = {
    plans: {},
    isLoading: false,
    isPaying: false
};


// -----------------
// Actions

export enum PlanActionType {
    CLEAR_ERROR = "@@plans/CLEAR_ERROR",
    LOAD_PLAN = "@@plans/LOAD_PLAN",
    PAY_FOR_PLAN = "@@plans/PAY_FOR_PLAN",
    PAYMENT_ERROR = "@@plans/PAYMENT_ERROR",
    RECEIVE_PLAN_DATA = "@@plans/RECEIVE_PLAN_DATA",
    SET_CURRENT_PLAN = "@@plans/SET_CURRENT_PLAN",
    USE_CACHED_PLAN_DATA = "@@plans/USE_CACHED_PLAN_DATA"
}

export namespace PlanActions {
    export interface LoadPlan {
        guid: string;
    }
    export interface PayForPlan {
        email: string;
        guid: string;
        selectedProject?: TrimbleProject;
        stripeToken: string;
    }
    export interface PaymentError {
        error: string;
    }
    export interface ReceivePlan {
        planData: PlanData;
    }
    export interface SetCurrentPlan {
        guid: string;
    }

    export const clearError = actionCreator(PlanActionType.CLEAR_ERROR).empty();
    export const loadPlan = actionCreator(PlanActionType.LOAD_PLAN).withPayload<LoadPlan>();
    export const payForPlan = actionCreator(PlanActionType.PAY_FOR_PLAN).withPayload<PayForPlan>();
    export const paymentError = actionCreator(PlanActionType.PAYMENT_ERROR).withPayload<PaymentError>();
    export const receivePlan = actionCreator(PlanActionType.RECEIVE_PLAN_DATA).withPayload<ReceivePlan>();
    export const setCurrentPlan = actionCreator(PlanActionType.SET_CURRENT_PLAN).withPayload<SetCurrentPlan>();
    export const useCachedPlan = actionCreator(PlanActionType.USE_CACHED_PLAN_DATA).empty();
}

export type AnyPlanAction = ActionsUnion<typeof PlanActions>;
export type PlanActions = ActionTypes<typeof PlanActions>;


// -----------------
// Epic

type PlanEpic = (action$: Rx.Observable<AnyPlanAction | LocationChangeAction>, store: StateObservable<ApplicationState>) => Rx.Observable<AnyPlanAction>;

const loadPlanEpic: PlanEpic = action$ => action$.pipe(
    ofType(PlanActionType.LOAD_PLAN),
    switchMap(action => createFetchStream<PlanData>(`api/plans/get/${action.payload.guid}`)
        .pipe(
            map(planData => PlanActions.receivePlan({ planData }))
        )));

const paymentEpic: PlanEpic = action$ => action$.pipe(
    ofType(PlanActionType.PAY_FOR_PLAN),
    flatMap(action => createFetchStream<PlanData>(`/api/plans/pay/${action.payload.guid}`, {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: stringify({
                stripeToken: action.payload.stripeToken,
                stripeEmail: action.payload.email,
                selectedFolderId: action.payload.selectedProject && action.payload.selectedProject.rootFolderId
            })
        })
        .pipe(
            map(planData => {
                return PlanActions.receivePlan({ planData });
            }),
            catchError(error => {
                return Rx.of(PlanActions.paymentError({ error: error.message }));
            })
        )));

const viewPlanMatcher = createMatchSelector<RouterRootState, { guid: string }>(viewPlanPath);
const selectGuidEpic: PlanEpic = action$ => action$.pipe(
    ofType(LOCATION_CHANGE),
    flatMap(action => {
        const viewPlanMatch = viewPlanMatcher({ router: action.payload });

        return viewPlanMatch
            ? Rx.of(PlanActions.setCurrentPlan({ guid: viewPlanMatch.params.guid }))
            : Rx.empty();
    }));

const switchPlanEpic: PlanEpic = (action$, store) => action$.pipe(
    ofType(PlanActionType.SET_CURRENT_PLAN),
    switchMap(action => store.value.plans.plans[action.payload.guid]
        ? Rx.empty()
        : Rx.of(PlanActions.loadPlan(action.payload))));

export const planEpic = combineEpics(loadPlanEpic, paymentEpic, selectGuidEpic, switchPlanEpic);


// ----------------
// Reducer


export const reducer: Reducer<PlansState> = (state: PlansState = initialState, incomingAction: Action) => {
    const action = incomingAction as AnyPlanAction | LocationChangeAction;
    switch (action.type) {
        case PlanActionType.CLEAR_ERROR:
            return {
                ...state,
                errorText: undefined,
                isPaying: false
            };
        case PlanActionType.PAY_FOR_PLAN:
            return {
                ...state,
                isPaying: true
            };
        case PlanActionType.PAYMENT_ERROR:
            return {
                ...state,
                errorText: action.payload.error
            };
        case PlanActionType.RECEIVE_PLAN_DATA:
            return {
                ...state,
                plans: {
                    ...state.plans,
                    [action.payload.planData.guid]: action.payload.planData
                },
                isLoading: false,
                isPaying: false
            };
        case PlanActionType.SET_CURRENT_PLAN:
            return state.currentPlanGuid === action.payload.guid
                ? state
                : {
                    ...state,
                    currentPlanGuid: action.payload.guid,
                    isLoading: !state.plans[action.payload.guid],
                    isPaying: false
                };
    }

    return state;
};
