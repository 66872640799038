import * as React from "react";


export interface CheckboxProps {
    checked: boolean;
    id: string;
    onChange: (checked: boolean) => void;
    children: string | string[];
}


export function Checkbox(props: CheckboxProps): JSX.Element {
    const checkboxHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.checked);
    }, [props.onChange]);

    return <label htmlFor={props.id} className="construction-ai-checkbox">
        <input type="checkbox" id={props.id} checked={props.checked} onChange={checkboxHandler} className="custom" />
        {props.children}
    </label>;
}
