import * as React from "react";
import Helmet from "react-helmet";

import { Layout } from "./Layout";


export function NotFound(): JSX.Element {
    return <Layout skipUser={true}>
        <Helmet>
            <title>404 File Not Found</title>
        </Helmet>
        <h2>404 - File not found</h2>
        <p>The file you selected could not be found; please try again.</p>
    </Layout>;
}
