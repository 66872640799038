import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import * as React from "react";

import { getServerOptions } from "../serverOptions";

import { CheckoutForm, CheckoutFormProps } from "./CheckoutForm";


type CheckoutFormContainerProps = CheckoutFormProps;


export function CheckoutFormContainer(props: CheckoutFormContainerProps): JSX.Element {
    const serverOptions = getServerOptions();
    const [stripeInstance, setStripeInstance] = React.useState<Stripe | null>(null);
    React.useEffect(() => {
        if (!stripeInstance) {
            // tslint:disable-next-line: no-require-imports
            require("@stripe/stripe-js")
                .loadStripe(serverOptions.stripePublicApiKey)
                .then((stripe: Stripe) => {
                    setStripeInstance(stripe);
                });
        }
    }, [serverOptions, stripeInstance]);

    return <Elements stripe={stripeInstance}>
        <CheckoutForm {...props} />
    </Elements>;
}
