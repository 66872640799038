import * as React from "react";

import { ServerOptions } from "../serverOptions";
import { PlanData } from "../store/plans";


interface LayeredDetailListProps {
    planData: PlanData;
    serverOptions: ServerOptions;
}


export function LayeredDetailList({ planData, serverOptions }: LayeredDetailListProps): JSX.Element {
    const outputAvailable = planData.available;

    return <ul>
        <li key="original-input">Original input PDF used is <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${planData.nameWithoutExtension}.pdf`}>{planData.nameWithoutExtension}.pdf</a></li>

        {outputAvailable && planData.layeredPages
            .map(page => {
                return <li key={`layers-${page.pageNumber}`}>Layered output for page {page.pageNumber}:
                    <ul key={`spot-output-${page.pageNumber}`}>
                        <li key={`dxf-output-layers-${page.pageNumber}`}>DXF output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${page.dxfOutputFile}`}>Download</a></li>
                        <li key={`pdf-output-layers-${page.pageNumber}`}>PDF output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${page.pdfOutputFile}`}>Download</a></li>
                    </ul>
                </li>
            })}
    </ul>;
}
