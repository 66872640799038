import { routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, compose, createStore, Store } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";

import * as StoreModule from "./store";
import { windowIfDefined } from "./utils/window";


declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(...funcs: Function[]) => (...args: any[]) => R;
    }
}


export function configureStore(history: History, initialState?: StoreModule.ApplicationState): Store<StoreModule.ApplicationState> {
    // if devTools is installed, connect to it
    const composeEnhancers = (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const epicMiddleware = createEpicMiddleware<StoreModule.AnyAction, StoreModule.AnyAction, StoreModule.ApplicationState>();
    const store = createStore(
        StoreModule.createRootReducer(history),
        <any>(initialState || StoreModule.rootInitialState),
        composeEnhancers(
            applyMiddleware(
                epicMiddleware,
                routerMiddleware(history))
        )
    );

    const epic$ = new BehaviorSubject<StoreModule.RootEpic>(StoreModule.rootEpic);
    // Every time a new epic is given to epic$ it
    // will unsubscribe from the previous one then
    // call and subscribe to the new one because of
    // how switchMap works
    const hotReloadingEpic: StoreModule.RootEpic = (action$, store$) =>
        epic$.pipe(
            switchMap(epic => epic(action$, store$, undefined))
        );

    epicMiddleware.run(hotReloadingEpic);

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept("./store", () => {
            // Justification: this is needed for hot reloading
            // tslint:disable-next-line:no-require-imports
            const nextStoreModule = require<typeof StoreModule>("./store");
            store.replaceReducer(nextStoreModule.createRootReducer(history));
            epic$.next(nextStoreModule.rootEpic);
        });
    }

    return store;
}
