import { Action, ActionCreatorsMapObject } from "redux";
import { ofType as originalOfType } from "redux-observable";
import { Observable } from "rxjs";


/**
 * Action with a discriminated type and a payload.
 */
export interface PayloadAction<T extends string, P> extends Action<T> {
    payload: P;
}

/**
 * Action with a discriminated type, payload, and metadata.
 */
export interface PayloadMetaAction<T extends string, P, M> extends PayloadAction<T, P> {
    meta: M;
}

/**
 * Generates a type union from an object containing action creators.
 */
export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

/**
 * Generates a type mapping of individual action names to their concrete types.
 */
export type ActionTypes<A extends ActionCreatorsMapObject> = { [K in keyof A]: ReturnType<A[K]> };

export interface EmptyActionCreator<T extends string, R = Action<T>> {
    /**
     * Creates an empty action.
     */
    (): R;
}

export interface PayloadActionCreator<T extends string, P, R = PayloadAction<T, P>> {
    /**
     * Creates an action with the given payload.
     *
     * @param payload
     *     Data to deliver with the action.
     */
    (payload: P): R;
}

export interface PayloadMetaActionCreator<T extends string, P, M, R = PayloadMetaAction<T, P, M>> {
    /**
     * Creates an action with the given payload and metadata.
     *
     * @param payload
     *     Data to deliver with the action.
     * @param meta
     *     Metadata needed for processing the action.
     */
    (payload: P, meta: M): R;
}

/**
 * For a given action type, allows generating various action creator functions.
 */
export interface ActionCreatorFactory<T extends string> {
    /**
     * Action creator with just a discriminated type and no payload.
     */
    empty(): EmptyActionCreator<T>;

    /**
     * Action creator with a discriminated type and payload.
     */
    withPayload<P>(): PayloadActionCreator<T, P>;

    /**
     * Action creator with a discriminated type, payload, and metadata.
     */
    withPayloadAndMeta<P, M>(): PayloadMetaActionCreator<T, P, M>;
}

/**
 * Returns an action creator factory for the given discriminated type.
 */
export function actionCreator<T extends string>(type: T): ActionCreatorFactory<T> {
    if (typeof type !== "string") {
        throw new Error(`actionCreator: expected "type" to be a string, instead got "${typeof type}".`);
    }

    function empty(): () => Action<T> {
        return () => ({ type });
    }

    function withPayload<P>(): (payload: P) => PayloadAction<T, P> {
        return payload => ({ type, payload });
    }

    function withPayloadAndMeta<P, M>(): (payload: P, meta: M) => PayloadMetaAction<T, P, M> {
        return (payload, meta) => ({ type, payload, meta });
    }

    return {
        empty,
        withPayload,
        withPayloadAndMeta
    };
}

/**
 * Helper type to automatically narrow the actions in the returned observable from `ofType`.
 */
export type FilteredOfType = <A extends Action, K extends string>(
    ...key: K[]
) => (source: Observable<A>) => Observable<Extract<A, { type: K }>>;

/**
 * Re-exported `ofType` from "redux-observable", but with type narrowing based on the provided type keys.
 */
export const ofType: FilteredOfType = originalOfType;
