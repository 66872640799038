import { AbortController } from "abort-controller";
import { addTask, fetch } from "domain-task";
import { Observable } from "rxjs";

import { windowIfDefined } from "./window";


declare global {
    interface Window {
        AbortController?: any;
    }
}


function getAbortController(): AbortController {
    if (windowIfDefined && windowIfDefined.AbortController) {
        return new windowIfDefined.AbortController();
    } else {
        return new AbortController();
    }
}


export function createFetchStream<T>(url: string | Request, init?: RequestInit): Observable<T> {
    return new Observable<T>(subscriber => {
        const controller = getAbortController();
        const fetchTask = fetch(
            url, {
                ...init,
                signal: controller.signal
            })
            .then(response => {
                if (response.ok) {
                    return (response.json() as Promise<T>)
                        .then(planData => {
                            subscriber.next(planData);
                            subscriber.complete();
                        });
                }

                return (response.text() as Promise<string>)
                    .then(errorString => {
                        throw new Error(errorString);
                    });
            })
            .catch(error => subscriber.error(error));

        addTask(fetchTask);

        return () => {
            controller.abort();
        };
    });
}

