import * as React from "react";


export interface TextboxProps {
    id: string;
    label: string;
    onChange: (newValue?: string) => void;
    type: string;
    value?: string;
}


export function Textbox(props: TextboxProps): JSX.Element {
    const textboxHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    }, [props.onChange]);

    return <p>{props.label}: <input type={props.type} id={props.id} value={props.value} onChange={textboxHandler}/></p>;
}
