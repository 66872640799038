import { windowIfDefined } from "./window";


export interface SavedUserState {
    username: string;
    email: string;
    clientToken: string;
}

const userStateKey = "userState";

export function loadUserState(): SavedUserState | undefined {
    const userStateJson = windowIfDefined && window.localStorage.getItem(userStateKey);
    if (userStateJson) {
        try {
            const parsedData = JSON.parse(userStateJson) as Partial<SavedUserState>;
            return parsedData.username && parsedData.email &&  parsedData.clientToken
                ? {
                    username: parsedData.username,
                    email: parsedData.email,
                    clientToken: parsedData.clientToken
                }
                : undefined;
        }
        catch {
            // if it is not parsable, remove it
            removeUserState();
        }
    }

    return undefined;
}

export function removeUserState(): void {
    if (windowIfDefined) {
        windowIfDefined.localStorage.removeItem(userStateKey);
    }
}

export function saveUserState(state: SavedUserState): void {
    if (windowIfDefined) {
        windowIfDefined.localStorage.setItem(userStateKey, JSON.stringify(state));
    }
}
