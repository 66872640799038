import * as React from "react";
import { GoogleLogo } from "./GoogleLogo";


export interface GoogleButtonProps {
    onClick: () => void;
    size: number;
}


export function GoogleButton(props: GoogleButtonProps): JSX.Element {
    const pixelSize = props.size / 32.0;
    const buttonStyle: React.CSSProperties = {
        lineHeight: 0,
        padding: 0,
        background: "#FFFFFF",
        display: "inline-flex"
    };
    const spanStyle: React.CSSProperties = {
        marginLeft: `${pixelSize*16}px`,
        marginRight: `${pixelSize*8}px`,
        fontFamily: "Roboto",
        fontSize: `${pixelSize*14}px`,
        color: "#8A8A8A",
        // verticalAlign: "bottom",
        lineHeight: `${pixelSize*36}px`,
        marginBottom: `-${pixelSize*8}px`
    }
    return <button onClick={props.onClick} style={buttonStyle}>
        <GoogleLogo size={pixelSize*18} margin={`${pixelSize*8}px`}></GoogleLogo>
        <span style={spanStyle}>Google</span>
    </button>;
}
