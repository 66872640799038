import { Action, Reducer } from "redux";
import { combineEpics, StateObservable } from "redux-observable";
import * as Rx from "rxjs";
import { catchError, flatMap, ignoreElements, map, switchMap, take, tap } from "rxjs/operators";

import { createFetchStream } from "../utils/fetch";
import { loginWithOAuth2, OAuth2Provider } from "../utils/oauth";
import { actionCreator, ofType, ActionsUnion, ActionTypes } from "../utils/state";
import { loadUserState, removeUserState, saveUserState } from "../utils/userState";

import { getServerOptions } from "../serverOptions";
import { ApplicationState } from "./index";


// -----------------
// State

export interface UserProfile {
    clientToken: string;
    email: string;
    provider: OAuth2Provider;
    username: string;
}

export interface UserState {
    profile: UserProfile | null;
    isLoggingIn: boolean;
    loginEnabled: boolean;
    loginError?: string;
}

export const initialState: UserState = {
    profile: null,
    isLoggingIn: false,
    loginEnabled: false
};


// -----------------
// Actions

export interface LoginResultBase {
    success: boolean;
}

export interface LoginResultSuccess {
    success: true;
    clientToken: string;
    email: string;
    provider: OAuth2Provider;
    username: string;
}

export interface LoginResultFailure {
    success: false;
    error: string;
}

export type LoginResult = LoginResultFailure | LoginResultSuccess;

export enum UserActionType {
    ENABLE_LOGINS = "@@user/ENABLE_LOGINS",
    LOAD_USER_STATE = "@@user/LOAD_USER_STATE",
    LOGIN_RESULT = "@@user/LOGIN_RESULT",
    LOGIN_START = "@@user/LOGIN_START",
    LOGOUT = "@@user/LOGOUT",
    LOGOUT_ACTUAL = "@@user/LOGOUT_ACTUAL"
}

export namespace UserActions {
    export const enableLogins = actionCreator(UserActionType.ENABLE_LOGINS).empty();
    export const loadUser = actionCreator(UserActionType.LOAD_USER_STATE).empty();
    export const loginResult = actionCreator(UserActionType.LOGIN_RESULT).withPayload<LoginResult>();
    export const loginStart = actionCreator(UserActionType.LOGIN_START).withPayload<OAuth2Provider>();
    export const logout = actionCreator(UserActionType.LOGOUT).empty();
    export const logoutActual = actionCreator(UserActionType.LOGOUT_ACTUAL).empty();
}

export type AnyUserAction = ActionsUnion<typeof UserActions>;
export type UserActions = ActionTypes<typeof UserActions>;


// -----------------
// Epic

type UserEpic = (action$: Rx.Observable<AnyUserAction>, store: StateObservable<ApplicationState>) => Rx.Observable<AnyUserAction>;

export const loadUserStateEpic: UserEpic = action$ => action$.pipe(
    ofType(UserActionType.LOAD_USER_STATE),
    switchMap(() => {
        const savedState = loadUserState();

        if (!savedState) {
            return Rx.empty();
        }

        return createFetchStream<LoginResult>(`api/auth/verify/${savedState.clientToken}`, { method: "POST" })
            .pipe(
                map(result => result && result.success
                    ? UserActions.loginResult(result)
                    : UserActions.logoutActual()),
                catchError(() => Rx.empty()));
    }));

export const loginEpic: UserEpic = action$ => action$.pipe(
    ofType(UserActionType.LOGIN_START),
    switchMap(action => {
        const serverOptions = getServerOptions();
        const selectedLoginOptions = serverOptions.oAuthConfigs[action.payload];
        if (!selectedLoginOptions) {
            return Rx.of(UserActions.loginResult({ success: false, error: "Attempting to login with invalid provider" }));
        }
        return loginWithOAuth2(selectedLoginOptions)
            .pipe(
                take(1),
                flatMap(result => {
                    return result.success
                        ? createFetchStream<LoginResult>(`api/auth/login/${result.provider}/${result.authorizationCode}`, { method: "POST" })
                            .pipe(
                                take(1)
                            )
                        : Rx.of<LoginResult>({
                            success: false,
                            error: result.errorMessage || result.error
                        });
                }),
                tap(result => {
                    if (result.success) {
                        saveUserState({
                            username: result.username,
                            email: result.email,
                            clientToken: result.clientToken
                        });
                    }
                }),
                map(result => UserActions.loginResult(result)),
                catchError(err => {
                    console.log("Got unknown error:", err);
                    return Rx.of(UserActions.loginResult({ success: false, error: "Unknown error occurred" }));
                })
            );
    }));

export const logoutEpic: UserEpic = action$ => action$.pipe(
    ofType(UserActionType.LOGOUT),
    switchMap(() => Rx.concat(
        createFetchStream<never>(`api/auth/logout`, { method: "POST" })
            .pipe(
                ignoreElements(),
                catchError(() => Rx.empty())),
        Rx.of(UserActions.logoutActual()))));

export const userEpic = combineEpics(loadUserStateEpic, logoutEpic, loginEpic);


// ----------------
// Reducer

export const reducer: Reducer<UserState> = (state: UserState = initialState, incomingAction: Action) => {
    const action = incomingAction as AnyUserAction;
    switch (action.type) {
        case UserActionType.ENABLE_LOGINS:
            return state.loginEnabled
                ? state
                : {
                    ...state,
                    loginEnabled: true
                };
        case UserActionType.LOGIN_RESULT:
            return {
                profile: action.payload.success ? {
                    clientToken: action.payload.clientToken,
                    email: action.payload.email,
                    provider: action.payload.provider,
                    username: action.payload.username
                } : null,
                isLoggingIn: false,
                loginEnabled: state.loginEnabled,
                loginError: action.payload.success ? undefined : action.payload.error
            };
        case UserActionType.LOGIN_START:
            return {
                profile: state.profile,
                isLoggingIn: true,
                loginEnabled: state.loginEnabled
            };
        case UserActionType.LOGOUT_ACTUAL:
            removeUserState();
            return {
                profile: null,
                isLoggingIn: false,
                loginEnabled: state.loginEnabled
            };
    }

    return state;
};
