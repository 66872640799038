import * as React from "react";

import { ServerOptions } from "../serverOptions";
import { FeaturePageData, PlanData, SpotPageData } from "../store/plans";


interface PlanDetailListProps {
    planData: PlanData;
    serverOptions: ServerOptions;
}


export function PlanDetailList({ planData, serverOptions }: PlanDetailListProps): JSX.Element {
    const outputAvailable = planData.available;
    const needToPay = !outputAvailable && !serverOptions.freeMode;
    const showDetails = !planData.paid;
    const featureCount = planData.featurePages.reduce((sum, page) => sum + page.featureCount, 0);
    const featureTypes: { [featureType: string]: boolean } = {};
    const featuresByPage: { [pageNumber: number]: FeaturePageData } = {};
    for (const featurePage of planData.featurePages) {
        for (const feature of featurePage.featuresIdentified) {
            if (feature.count > 0) {
                featureTypes[feature.name.toLocaleLowerCase()] = true;
            }
        }
        if (featurePage.featureCount > 0) {
            featuresByPage[featurePage.pageNumber] = featurePage;
        }
    }
    const tableData = planData.tableData;
    const tablesFound = tableData && tableData.tableCount > 0;
    const crossSectionData = planData.crossSectionData;
    const crossSectionsFound = crossSectionData && crossSectionData.crossSectionCount > 0;
    const spotsByPage: { [pageNumber: number]: SpotPageData } = {};
    for (const spotPage of planData.spotPages) {
        if (spotPage.spotsFound > 0) {
            spotsByPage[spotPage.pageNumber] = spotPage;
        }
    }
    const spotsFound = planData.spotPages.reduce((sum, page) => sum + page.spotsFound, 0) > 0;
    const spotsConfident = planData.spotPages.reduce((sum, page) => sum + page.spotsConfident, 0);
    const spotsUncertain = planData.spotPages.reduce((sum, page) => sum + page.spotsUncertain, 0);
    const spotsUnmatched = planData.spotPages.reduce((sum, page) => sum + page.spotsUnmatched, 0);
    const layers = planData.spotPages.reduce((sum, page) => sum + page.layerCount, 0);
    const vectors = planData.spotPages.reduce((sum, page) => sum + page.vectorCount, 0);

    return <ul>
        <li key="original-input">the original input PDF used is <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${planData.nameWithoutExtension}.pdf`}>{planData.nameWithoutExtension}.pdf</a></li>

        {showDetails && spotsFound && <li key="confident"><b>{spotsConfident}</b> spot elevations with fully x,y,z coordinates converted to DXF 3D.</li>}
        {showDetails && spotsFound && <li key="less-confident"><b>{spotsUncertain}</b> possible spot elevations with x,y,z coordinates converted to DXF in 3D.</li>}
        {showDetails && spotsFound && <li key="unmatched"><b>{spotsUnmatched}</b> coordinates found with x,y values converted to DXF.</li>}

        {showDetails && <li key="layers"><b>{layers}</b> new layers created and converted to DXF.</li>}
        {showDetails && featureCount > 0 && <li key="feature-counts"><b>{featureCount}</b> objects of interest found and printed to summary PDF.</li>}
        {showDetails && <li key="vectors"><b>{vectors}</b> vectors (polylines) extracted.</li>}

        {showDetails && tablesFound && <li key="table-counts"><b>{tableData.tableCount}</b> legends found.</li>}

        {showDetails && crossSectionsFound && <li key="cross-section-counts"><b>{crossSectionData.crossSectionCount}</b> cross section plots found.</li>}

        {needToPay && <li key="cost"><b>{planData.costDisplay}</b> {serverOptions.currencyCode} to purchase DXF (and summary PDF).</li>}

        {outputAvailable && (spotsFound || featureCount > 0) && planData.spotPages
            .map(page => {
                return <li key={`spots-${page.pageNumber}`}>Results for page {page.pageNumber}:
                    <ul key={`spot-output-${page.pageNumber}`}>
                        <li key={`spot-count-${page.pageNumber}`}><b>{page.spotsConfident}</b> spot elevations found.</li>
                        <li key={`dxf-output-spots-${page.pageNumber}`}>DXF output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${page.dxfOutputFile}`}>Download</a></li>
                        <li key={`pdf-output-spots-${page.pageNumber}`}>PDF output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${page.pdfOutputFile}`}>Download</a></li>
                    </ul>
                </li>
            })}

        {outputAvailable && tablesFound && <li key="excel-output-tables">Tables Excel output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${tableData.excelOutputFile}`}>Download</a></li>}
        {outputAvailable && tablesFound && <li key="pdf-output-tables">Tables PDF output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${tableData.pdfOutputFile}`}>Download</a></li>}

        {outputAvailable && crossSectionsFound && <li key="dxf-output-cross-sections">Cross sections DXF output file: <a href={`https://${serverOptions.publishedDataHost}/published/${planData.guid}/${crossSectionData.dxfOutputFile}`}>Download</a></li>}
    </ul>;
}
