import * as React from "react";

import { createFetchStream } from "../utils/fetch";

import { Picklist } from "./Picklist";
import { PleaseWait } from "./PleaseWait";


export interface TrimbleProject {
    id: string;
    name: string;
    rootFolderId: string;
}


export interface TrimbleProjectListProps {
    id?: string;
    onSelection: (selectedProject?: TrimbleProject) => void;
    selectedProject?: TrimbleProject;
}


export function TrimbleProjectList(props: TrimbleProjectListProps): JSX.Element {
    const [projects, setProjects] = React.useState<TrimbleProject[]>();
    React.useEffect(() => {
        createFetchStream<TrimbleProject[]>(`api/trimble/get/projects`)
            .subscribe(
                loadedProjects => {
                    if (loadedProjects && loadedProjects.length) {
                        props.onSelection(loadedProjects[0]);
                    }
                    setProjects(loadedProjects || []);
                },
                _err => {
                    // TODO do something!
                }
            );
    }, []);

    return projects
        ? <Picklist id={props.id} items={projects} onSelection={props.onSelection} selectedItem={props.selectedProject}></Picklist>
        : <PleaseWait>Loading project list</PleaseWait>;
}
