import * as React from "react";
import Helmet from "react-helmet";
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { AnyAction, ApplicationState }  from "../store";
import { UploadActions, UploadState } from "../store/upload";

import { Layout } from "./Layout";
import { PleaseWait } from "./PleaseWait";


type HomeProps =
    UploadState
    & RouteComponentProps<void>
    & DispatchProp<AnyAction>;


function Home(props: HomeProps): JSX.Element {
    const buttonHandler = React.useCallback(() => {
        const filenameElement = document.getElementById("filename") as HTMLInputElement;
        if (filenameElement) {
            filenameElement.click();
        }
        return false;
    }, []);
    const hiddenFileHandler = React.useCallback(() => {
        const filenameElement = document.getElementById("filename") as HTMLInputElement;

        const files = filenameElement && filenameElement.files;
        if (files) {
            props.dispatch(UploadActions.uploadPlan({
                file: files[0],
                filename: files[0].name
            }));
        }

        return false;
    }, [props.dispatch]);
    const cancelUpload = React.useCallback(
        () => props.dispatch(UploadActions.cancelUpload()),
        [props.dispatch]);
    const clearError = React.useCallback(
        () => props.dispatch(UploadActions.clearError()),
        [props.dispatch]);


    return <Layout>
        <Helmet>
            <title>PDF to Point Cloud Survey Conversion</title>
        </Helmet>
        <h2>PDF to Point Cloud Survey Conversion</h2>
        <p>Upload a PDF of a vectorized <i>survey</i> and get a 3D Point Cloud in DXF instantly.</p>
        {props.isUploading ? renderUploadingProgress(props.uploadMessage, cancelUpload)
            : props.errorText ? renderError(props.errorText, clearError)
            : renderUploadForm(buttonHandler, hiddenFileHandler)
        }
    </Layout>;
}


function renderUploadingProgress(uploadMessage: string | undefined, cancelUpload: () => void): JSX.Element {
    return <div>
        <PleaseWait>{uploadMessage || ""}</PleaseWait>
        <p><a className="link" onClick={cancelUpload}>Cancel</a></p>
    </div>;
}


function renderError(errorText: string, clearError: () => void): JSX.Element {
    return <p>Error encountered on upload: "{errorText}" <a className="link" onClick={clearError}>Try again?</a></p>;
}


function renderUploadForm(buttonHandler: () => boolean, hiddenFileHandler: () => boolean): JSX.Element {
    return <form encType="multipart/form-data" action="/api/plans/uploadPlan" method="post">
        <div className="sqs-button-wrapper">
            <a className="sqs-button" onClick={buttonHandler}>Upload Survey PDF</a>
        </div>
        <input className="hidden" type="file" accept=".pdf" name="file" id="filename"
            onChange={hiddenFileHandler} autoComplete="off"/>
    </form>;
}


export const ConnectedHome = connect(
    (state: ApplicationState) => state.upload
)(Home);
