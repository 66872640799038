import * as React from "react";

import { sendResultMessage } from "../utils/oauth";
import { windowIfDefined } from "../utils/window";


export function OAuth2Redirect(): JSX.Element {
    React.useEffect(() => {
        if (windowIfDefined && windowIfDefined.location) {
            sendResultMessage(windowIfDefined.location);
        }
    });

    return <h2>
        Completing login...
    </h2>;
}
