import * as React from "react";
import { connect, DispatchProp } from "react-redux";

import { getServerOptions } from "../serverOptions";
import { AnyAction, ApplicationState }  from "../store";
import { UserActions, UserState } from "../store/user";
import { OAuth2Provider } from "../utils/oauth";
import { GoogleButton } from "./GoogleButton";
import { PleaseWait } from "./PleaseWait";


type LoginButtonProps =
    UserState
    & DispatchProp<AnyAction>;


function LoginButton(props: LoginButtonProps): JSX.Element {
    const serverOptions = getServerOptions();
    const logout = React.useCallback(
        () => props.dispatch(UserActions.logout()),
        [props.dispatch]);
    const loginStartGoogle = React.useCallback(
        () => props.dispatch(UserActions.loginStart(OAuth2Provider.Google)),
        [props.dispatch]);
    const loginStartProcore = React.useCallback(
        () => props.dispatch(UserActions.loginStart(OAuth2Provider.Procore)),
        [props.dispatch]);
    const loginStartTrimble = React.useCallback(
        () => props.dispatch(UserActions.loginStart(OAuth2Provider.TrimbleConnect)),
        [props.dispatch]);

    const loginOptions = <>
        {serverOptions.oAuthConfigs.Google && <GoogleButton onClick={loginStartGoogle} size={32}></GoogleButton>}
        {serverOptions.oAuthConfigs.TrimbleConnect && serverOptions.oAuthConfigs.Google && " or "}
        {serverOptions.oAuthConfigs.TrimbleConnect && <a className="link" onClick={loginStartTrimble}>Trimble Connect</a>}
        {serverOptions.oAuthConfigs.Procore && (serverOptions.oAuthConfigs.Google || serverOptions.oAuthConfigs.TrimbleConnect) && " or "}
        {serverOptions.oAuthConfigs.Procore && <a className="link" onClick={loginStartProcore}>Procore</a>}
    </>;

    return props.loginEnabled
        ? props.profile
            ? <p>Hello {props.profile.username} ({props.profile.provider}) <a className="link" onClick={logout}>Logout</a></p>
            : props.isLoggingIn
                ? <PleaseWait>Login in progress</PleaseWait>
                : props.loginError
                    ? <p>Error: {props.loginError} Try again with {loginOptions}?</p>
                    : <p>Login with {loginOptions}</p>
        : <div className="hidden"></div>;
}


export const ConnectedLoginButton = connect(
    (state: ApplicationState) => state.user
)(LoginButton);
