import * as React from "react";
import { ConnectedLoginButton } from "./LoginButton";

import { buildVersion } from "../version";


interface LayoutProps {
    skipUser?: boolean;
    children: React.ReactNode[];
}


export function Layout(props: LayoutProps): JSX.Element {
    return <div className="root-container">
        <header>
            <a href="/"><img src="/static/logo-150.png" alt="Construction AI" width="150" height="150" /></a>
        </header>
        <div className="main-content">
            {props.skipUser
                ? props.children
                : [
                    <ConnectedLoginButton key="login-button"></ConnectedLoginButton>,
                    ...props.children
                ] }
        </div>
        <footer>
            <a href="http://www.construction.ai/privacy/">Privacy</a>
            <a href="http://www.construction.ai/terms-of-service/">Terms of Service</a>
            <a href="http://www.construction.ai/contact-us/">Contact</a>
            {buildVersion}
        </footer>
    </div>;
}
