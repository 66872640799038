import { push } from "connected-react-router";
import { Action, Reducer } from "redux";
import { StateObservable } from "redux-observable";
import * as Rx from "rxjs";
import { catchError, filter, flatMap, switchMap, take, takeUntil } from "rxjs/operators";

import { actionCreator, ofType, ActionsUnion, ActionTypes } from "../utils/state";

import { createFetchStream } from "../utils/fetch";
import { createQueueStream } from "../utils/messageQueue";
import { AnyAction, ApplicationState } from "./index";
import { PlanActions, PlanData } from "./plans";


// -----------------
// State

export interface UploadState {
    errorText?: string;
    uploadMessage?: string;
    isUploading: boolean;
}

export const initialState: UploadState = { isUploading: false };


// -----------------
// Actions

export enum UploadActionType {
    CANCEL = "@@upload/CANCEL",
    CLEAR_ERROR = "@@upload/CLEAR_ERROR",
    UPLOAD_ERROR = "@@upload/UPLOAD_ERROR",
    UPLOAD_FINISHED = "@@upload/UPLOAD_FINISHED",
    UPLOAD_PLAN = "@@upload/UPLOAD_PLAN",
    UPLOAD_STATUS = "@@upload/UPLOAD_STATUS"
}

export namespace UploadActions {
    export interface UploadError {
        error: string;
    }
    export interface UploadStatus {
        status: string;
    }
    export interface UploadFinished {
        planData: PlanData;
    }
    export interface UploadPlan {
        file: File;
        filename: string;
    }

    export const cancelUpload = actionCreator(UploadActionType.CANCEL).empty();
    export const clearError = actionCreator(UploadActionType.CLEAR_ERROR).empty();
    export const uploadError = actionCreator(UploadActionType.UPLOAD_ERROR).withPayload<UploadError>();
    export const uploadFinished = actionCreator(UploadActionType.UPLOAD_FINISHED).withPayload<UploadFinished>();
    export const uploadPlan = actionCreator(UploadActionType.UPLOAD_PLAN).withPayload<UploadPlan>();
    export const uploadStatus = actionCreator(UploadActionType.UPLOAD_STATUS).withPayload<UploadStatus>();
}

export type AnyUploadAction = ActionsUnion<typeof UploadActions>;
export type UploadActions = ActionTypes<typeof UploadActions>;


// -----------------
// Epic

type UploadEpic = (action$: Rx.Observable<AnyUploadAction>, store: StateObservable<ApplicationState>) => Rx.Observable<AnyAction>;

interface UploadResult {
    guid: string;
    messageQueue: string;
}

interface UploadMessageComplete {
    complete: true;
    message: string;
    planData: PlanData;
}

interface UploadMessageStatus {
    complete: false;
    error?: boolean;
    message: string;
    planData: PlanData;
}

type UploadMessage = UploadMessageComplete | UploadMessageStatus;

export const uploadEpic: UploadEpic = action$ => action$.pipe(
    ofType(UploadActionType.UPLOAD_PLAN),
    switchMap(action => {
        const data = new FormData();
        data.append("file", action.payload.file, action.payload.filename);

        return createFetchStream<UploadResult>("/api/plans/uploadPlan",
            {
                method: "POST",
                body: data
            })
            .pipe(
                take(1),
                switchMap(uploadResult =>
                    createQueueStream<UploadMessage>(uploadResult.messageQueue)),
                takeUntil(action$.pipe(filter(a => a.type === UploadActionType.CANCEL || a.type === UploadActionType.UPLOAD_ERROR || a.type === UploadActionType.UPLOAD_FINISHED))),
                flatMap(message => message.complete
                    ? [
                        UploadActions.uploadFinished({ planData: message.planData }),
                        PlanActions.receivePlan({ planData: message.planData }),
                        push(`/view/${message.planData.guid}`)
                    ]
                    : message.error
                        ? [ UploadActions.uploadError({ error: message.message }) ]
                        : [ UploadActions.uploadStatus({ status: message.message }) ]),
                catchError(error =>
                    Rx.of(UploadActions.uploadError({ error: error.message })))
            );
    }));


// ----------------
// Reducer

export const reducer: Reducer<UploadState> = (state: UploadState = initialState, incomingAction: Action) => {
    const action = incomingAction as AnyUploadAction;
    switch (action.type) {
        case UploadActionType.CANCEL:
        case UploadActionType.CLEAR_ERROR:
        case UploadActionType.UPLOAD_FINISHED:
            return {
                isUploading: false
            };
        case UploadActionType.UPLOAD_ERROR:
            return {
                errorText: action.payload.error,
                isUploading: false
            };
        case UploadActionType.UPLOAD_STATUS:
            return {
                uploadMessage: action.payload.status,
                isUploading: true
            };
        case UploadActionType.UPLOAD_PLAN:
            return {
                uploadMessage: `Uploading ${action.payload.filename}`,
                isUploading: true
            };
    }

    // TODO handle the fact that this breaks the switch above...
    return state;
};
