import { connectRouter, RouterAction, RouterState } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { combineEpics, Epic } from "redux-observable";

import * as Plans from "./plans";
import * as Upload from "./upload";
import * as User from "./user";


// -----------------
// State

export interface ApplicationState {
    plans: Plans.PlansState;
    router?: RouterState;
    upload: Upload.UploadState;
    user: User.UserState;
}

export const rootInitialState: ApplicationState = {
    plans: Plans.initialState,
    upload: Upload.initialState,
    user: User.initialState
};


// -----------------
// Actions

export type AnyAction = Plans.AnyPlanAction
    | Upload.AnyUploadAction
    | User.AnyUserAction
    | RouterAction;


// -----------------
// Epic

export type RootEpic = Epic<AnyAction, AnyAction, ApplicationState>;

export const rootEpic: RootEpic = combineEpics(
    Plans.planEpic,
    Upload.uploadEpic,
    User.userEpic
);


// ----------------
// Reducer

export const createRootReducer = (history: History) => combineReducers<ApplicationState>({
    plans: Plans.reducer,
    router: <any>connectRouter(history),
    upload: Upload.reducer,
    user: User.reducer
});
