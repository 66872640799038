import * as React from "react";
import Helmet from "react-helmet";
import { connect, DispatchProp } from "react-redux";
import { NavLink } from "react-router-dom";

import { getServerOptions, ServerOptions } from "../serverOptions";
import { AnyAction, ApplicationState }  from "../store";
import { PlansState, PlanActions, PlanData } from "../store/plans";
import { UserProfile, UserState } from "../store/user";
import { OAuth2Provider } from "../utils/oauth";

import { StripeSubmitCallback } from "./CheckoutForm";
import { CheckoutFormContainer } from "./CheckoutFormContainer";
import { LayeredDetailList } from "./LayeredDetailList";
import { Layout } from "./Layout";
import { NotFound } from "./NotFound";
import { PlanDetailList } from "./PlanDetailList";
import { PleaseWait } from "./PleaseWait";
import { TrimbleProject } from "./TrimbleProjectList";


type ViewPlanProps = PlansState
    & UserState
    & DispatchProp<AnyAction>;


function ViewPlan(props: ViewPlanProps): JSX.Element {
    const serverOptions = getServerOptions();
    const planData = props.plans[props.currentPlanGuid || ""];
    React.useEffect(() => {
        if (serverOptions.freeMode && props.currentPlanGuid && props.profile && planData && !planData.available) {
            props.dispatch(PlanActions.loadPlan({ guid: props.currentPlanGuid }));
        }
    }, [props.currentPlanGuid, props.profile]);

    const stripeSubmitCallback = React.useCallback((stripeToken: string, email: string, selectedProject?: TrimbleProject): void => {
        props.dispatch(PlanActions.payForPlan({
            email,
            guid: props.currentPlanGuid || "",
            selectedProject,
            stripeToken
        }));

    }, [props.dispatch, props.currentPlanGuid]);

    if (props.isLoading) {
        return renderLoading();
    }

    if (planData) {
        if (planData.nothing) {
            if (planData.available) {
                return renderNothing(planData, serverOptions, props.profile);
            } else {
                return renderNothingLoginRequired(planData);
            }
        } else if (serverOptions.freeMode) {
            if (planData.available) {
                return renderFreeMode(planData, serverOptions, props.profile);
            } else {
                return renderFreeModeLoginRequired(planData, serverOptions);
            }
        } else if (planData.free) {
            return renderFree(planData, serverOptions);
        } else if (planData.paid) {
            return renderPaid(planData, serverOptions);
        } else {
            return renderUnpaid(props, planData, serverOptions, stripeSubmitCallback);
        }
    } else {
        return <NotFound></NotFound>;
    }
}


function countSpots(planData: PlanData, confidentOnly: boolean = false): number {
    return planData.spotPages.reduce((sum, page) => sum + (confidentOnly ? page.spotsConfident : page.spotsFound), 0);
}


function renderFree(planData: PlanData, serverOptions: ServerOptions): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Results for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Point Cloud Conversion Summary</h2>
        <p>
        We found only {countSpots(planData, true)} spot elevations and {planData.tableData.tableCount} legends, so this one is on us!
        </p>
        <p>
        Here's a quick summary:
        </p>
        <PlanDetailList planData={planData} serverOptions={serverOptions}></PlanDetailList>
        <ResultsFooter></ResultsFooter>
    </Layout>;
}


function renderFreeMode(planData: PlanData, serverOptions: ServerOptions, profile: UserProfile | null): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Results for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Point Cloud Conversion Summary</h2>
        <p>
        Congratulations{(profile ? ` ${profile.username}` : "")}, Construction AI fully converted {countSpots(planData, true)} spot elevations and {planData.tableData.tableCount} legends from "{planData.nameWithoutExtension}.pdf".
        </p>
        <p>
        Here's a quick summary:
        </p>
        <PlanDetailList planData={planData} serverOptions={serverOptions}></PlanDetailList>
        <ResultsFooter></ResultsFooter>
    </Layout>;
}


function renderFreeModeLoginRequired(planData: PlanData, serverOptions: ServerOptions): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Preview for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Point Cloud Conversion Summary</h2>
        <p>
        Congratulations, Construction AI fully converted {countSpots(planData, true)} spot elevations and {planData.tableData.tableCount} legends from "{planData.nameWithoutExtension}.pdf".
        All you have to do to see the results is log in!
        </p>
        <p>
        Here's a quick summary:
        </p>
        <PlanDetailList planData={planData} serverOptions={serverOptions}></PlanDetailList>
        <ResultsFooter></ResultsFooter>
    </Layout>;
}


function renderLoading(): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Loading...</title>
        </Helmet>
        <h2>Loading...</h2>
        <PleaseWait>Please wait while the results load</PleaseWait>
    </Layout>;
}


function renderNothing(planData: PlanData, serverOptions: ServerOptions, profile: UserProfile | null): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Results for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Let’s Try Again!</h2>
        <p>
        Hi{(profile ? ` ${profile.username}` : "")},
        </p>
        <p>
        It looks like we may have had trouble converting "{planData.nameWithoutExtension}.pdf" into a point cloud. There are a few reasons why this may have been:
        </p>
        <ol>
        <li key="not-vectors">If the spot elevations aren’t vectors (polylines) we’re unable to process them.</li>
        <li key="raster">If the file is raster (like a scanned document) we’re also unable to do a conversion.</li>
        <li key="shrug">It could also just be one of those things, and we’d love to help get you the data you need. Please <a href="https://www.construction.ai/contact-us">email us</a> and we’ll see what we can do to help you.</li>
        </ol>
        <p>
        In the meantime, feel free to download these output files:
        </p>
        <LayeredDetailList planData={planData} serverOptions={serverOptions}></LayeredDetailList>
        <NoResultsFooter></NoResultsFooter>
    </Layout>;
}


function renderNothingLoginRequired(planData: PlanData): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Results for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Let’s Try Again!</h2>
        <p>
        Hi,
        </p>
        <p>
        It looks like we may have had trouble converting "{planData.nameWithoutExtension}.pdf" into a point cloud. There are a few reasons why this may have been:
        </p>
        <ol>
        <li key="not-vectors">If the spot elevations aren’t vectors (polylines) we’re unable to process them.</li>
        <li key="raster">If the file is raster (like a scanned document) we’re also unable to do a conversion.</li>
        <li key="shrug">It could also just be one of those things, and we’d love to help get you the data you need. Please <a href="https://www.construction.ai/contact-us">email us</a> and we’ll see what we can do to help you.</li>
        </ol>
        <p>
        In the meantime, if you log in you can download a layered output file.
        </p>
        <NoResultsFooter></NoResultsFooter>
    </Layout>;
}


function renderPaid(planData: PlanData, serverOptions: ServerOptions): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Results for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Point Cloud Conversion Summary</h2>
        <p>
        Congratulations, Construction AI fully converted {countSpots(planData, true)} spot elevations and {planData.tableData.tableCount} legends from "{planData.nameWithoutExtension}.pdf".
        </p>
        <p>
        Here's a quick summary:
        </p>
        <PlanDetailList planData={planData} serverOptions={serverOptions}></PlanDetailList>
        <ResultsFooter></ResultsFooter>
    </Layout>;
}


function renderUnpaid(props: ViewPlanProps, planData: PlanData, serverOptions: ServerOptions, stripeSubmitCallback: StripeSubmitCallback): JSX.Element {
    return <Layout>
        <Helmet>
            <title>Preview for {planData.nameWithoutExtension}.pdf</title>
        </Helmet>
        <h2>Point Cloud Conversion Summary</h2>
        <p>
        Congratulations, Construction AI fully converted {countSpots(planData, true)} spot elevations and {planData.tableData.tableCount} legends from "{planData.nameWithoutExtension}.pdf".
        </p>
        <p>
        Here's a quick summary:
        </p>
        <PlanDetailList planData={planData} serverOptions={serverOptions}></PlanDetailList>
        <CheckoutFormContainer
            costDisplay={planData.costDisplay}
            currencyCode={serverOptions.currencyCode}
            email={props.profile && props.profile.email}
            isTrimbleEmail={!!props.profile && props.profile.provider === OAuth2Provider.TrimbleConnect}
            isPaying={props.isPaying}
            onSubmit={stripeSubmitCallback}
            errorText={props.errorText}
            clearError={() => props.dispatch(PlanActions.clearError())} />
        <NoResultsFooter></NoResultsFooter>
    </Layout>;
}

function NoResultsFooter(): JSX.Element {
    return <p>
        Or, do you have another survey to try? Go ahead: <NavLink exact to={"/"}>upload another plan</NavLink>!
    </p>;
}

function ResultsFooter(): JSX.Element {
    return <p>
        Got even more work to do? Go ahead: <NavLink exact to={"/"}>upload another plan</NavLink>!
        <br></br>
        Not what you expected? We’d like to hear about it! <a href="https://www.construction.ai/contact-us">Contact us here</a>.
    </p>;
}


export const ConnectedViewPlan = connect(
    (state: ApplicationState) => ({
        ...state.plans,
        ...state.user
    })
)(ViewPlan);
